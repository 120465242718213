import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { Toast } from 'vant'
import store from 'storejs'
import 'vant/es/toast/style';
import router from '@/router';
import vuexState from '@/store'

type TAxiosOption = {
    baseURL: string
    timeout: number
}

// 解决类型“AxiosResponse＜any, any＞”上不存在属性问题解决方法
declare module "axios" {
    interface AxiosResponse<T = any> {
      errorinfo: null;
      code: null;
      data: T;
      msg: T
    }
    export function create(config?: AxiosRequestConfig): AxiosInstance;
}

const config = {
    // baseURL: process.env.VUE_APP_MINI_APP + process.env.VUE_APP_API_BASE_URL,
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 20000
}

// 不需要加载动画的路由
const list: string[] = ['/auth/token','/auth/user/signin','/auth/collect', '/auth/collect/delete', '/auth/user/code/out', '/auth/github/login', '/auth/gitee/login','/answer/submit_comment']

class HttpRequest {
    private readonly baseUrl: string
    private readonly timeout: number
    constructor(config: TAxiosOption) {
        this.baseUrl = config.baseURL
        this.timeout = config.timeout
    }
    getInsideConfig() {
        return {
            baseURL: this.baseUrl,
            timeout: this.timeout
        }
    }

    // 请求拦截
    interceptors(instance: AxiosInstance, url: string | number | undefined | any) {
        instance.interceptors.request.use(
            config => {
                if(!list.includes(url)) {
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true
                    })
                }
                // 请求头携带token
                const tokens = store.get('token')
                if (tokens) {
                    ;(config.headers as AxiosRequestHeaders).authorization ="Bearer "+ tokens
                }
                // (config.headers as AxiosRequestHeaders).authorization = 'Bearer yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiMjU4NTcxNzE0OEBxcS5jb20iLCJzdWIiOjg0ODM2NDEsImlhdCI6MTY3MjQxMzUxMCwiZXhwIjoxNjcyNDE0MTEwfQ.27mTnAPBDBD6cuBznvWk4zxoZQGM5JNyAP2OCccjC9'
                return config
            },
            (error: any) => {
                return Promise.reject(error)
            }
        )
        //响应拦截
        instance.interceptors.response.use(
            res => {
                //返回数据
                const { data } = res
                Toast.clear()
                return data
            },
            (error: any) => {
                if (error.response.status === 401) {
                    store.remove('token')
                    store.remove('userInfo')
                    router.replace({name: 'login'})
                    vuexState.state.userInfo = {}
                }
                Toast.clear()
                return Promise.reject(error)
            }
        )
    }

    request(options: AxiosRequestConfig) {
        const instance = axios.create()
        options = Object.assign(this.getInsideConfig(), options)
        this.interceptors(instance, options.url)
        return instance(options)
    }
}

const http = new HttpRequest(config)
export default http
