// import { updateUser } from '../types/userInfo'
import http from '../utils/request'

interface updateUser {
    uuid: string
    avatar?: string
    username?: string
}

const serveUrl: any = {
    seedCode: '/auth/login/email',
    emailLogin: '/auth/register',
    passLogin: 'auth/login',
    githubLogin: '/auth/github/login',
    giteeLogin: '/auth/gitee/login',
    getAvatar: '/auth/avatar/list',
    updateAvatar: '/auth/avatar/update',
    updateUsername: '/auth/username/update',
    token: '/auth/token',
    allpass: 'auth/login/pass',
    addCode: '/auth/user/signin',
    outCode: '/auth/user/code/out',
    vip: '/auth/conver/vip',
    addCodeRedis: '/auth/user/redis/code/add',
    dailyIntegral: '/auth/user/ingter/code',
    ingterDetails: 'auth/user/ingter/details',
    loginPass: '/auth/login/pass'
}

// 发送验证码
export const seedCode = (data: any) => {
    return http.request({
        url: serveUrl.seedCode,
        method: 'POST',
        data
    })
}

// 无需注册一键登录
export const emailLogin = (data: any) => {
    return http.request({
        url: serveUrl.emailLogin,
        method: 'POST',
        data
    })
}

// 密码一键登录
export const passLogin = (data: any) => {
    return http.request({
        url: serveUrl.passLogin,
        method: 'POST',
        data
    })
}

// github 授权登录
export const githubLogin = (data: any) => {
    return http.request({
        url: serveUrl.githubLogin,
        method: 'POST',
        data
    })
}

// gitee 授权登录
export const giteeLogin = (data: any) => {
    return http.request({
        url: serveUrl.giteeLogin,
        method: 'POST',
        data
    })
}

// 获取头像
export const getAvatar = () => {
    return http.request({
        url: serveUrl.getAvatar,
        method: 'GET'
    })
}

// 修改头像
export const updateAvatar = (data: updateUser) => {
    return http.request({
        url: serveUrl.updateAvatar,
        method: 'POST',
        data
    })
}
// 修改用户名
export const updateUsername = (data: updateUser) => {
    return http.request({
        url: serveUrl.updateUsername,
        method: 'POST',
        data
    })
}

// 补全密码 
export const allpass = (data: {pass: string, user_id: number}) => {
    return http.request({
        url: serveUrl.allpass,
        method: 'POST',
        data
    })
}

// token是否过期
export const isToken = () => {
    return http.request({
        url: serveUrl.token,
        method: 'GET'
    })
}


// 新增积分
export const addCode = (data: {user_id: number, name: string}) => {
    return http.request({
        url: serveUrl.addCode,
        method: 'POST',
        data
    })
}

// 答题新增积分-redis
export const addCodeRedis = (data: {user_id: number, score: number | string}) => {
    return http.request({
        url: serveUrl.addCodeRedis,
        method: 'POST',
        data
    })
}

// 扣除积分
export const outCode = (data: {user_id: number, name: string}) => {
    return http.request({
        url: serveUrl.outCode,
        method: 'POST',
        data
    })
}

// vip兑换
export const vip = (data: {userId: number, code: string}) => {
    return http.request({
        url: serveUrl.vip,
        method: 'POST',
        data
    })
}

// 获取今日获得的积分
export const dailyIntegral = (user_id: number) => {
    return http.request({
        url: serveUrl.dailyIntegral,
        method: 'GET',
        params: {
            user_id
        }
    })
}

// 积分明细
export const ingterDetails = (user_id: number) => {
    return http.request({
        url: serveUrl.ingterDetails,
        method: "GET",
        params: {
            user_id
        }
    })
}

// 补全密码
export const loginPass = (data: {pass: string, user_id: number}) => {
    return http.request({
        url: serveUrl.loginPass,
        method: "POST",
        data
    })
}