interface Verify<T> {
    ans: string[]
    titleId?: string
    rights: string | T
}
function transformQuestion(qus: Verify<any>) {
    // console.log(qus)
    var rightsOption = qus.rights
    var answers = [...qus.ans]
    if (typeof rightsOption === 'string') rightsOption = rightsOption.split(',')
    var trans = {
        multiple: rightsOption.length > 1, // 是否为多选题，答案数量 > 1 时为多选
        answers: answers,
        rights: rightsOption
            .map(function (opt: string) {
                var index = opt.toUpperCase().charCodeAt(0) - 65 // 获取选项索引
                return answers[index]
            })
            .sort()
    }
    // 返回核对题目的函数，这里的函数可以继续按细节完善，这里只判断是否完全正确
    return function checkUserInput(input: any) {
        if (typeof input === 'string') input = input.split(',')
        return trans.rights.join(',') === input.sort().join(',')
    }
}

export const check = (questionItme: Verify<any>, answer: string[]) => {
    let isVerify = transformQuestion(questionItme)
    return isVerify(answer)
}

// 测试函数
// var question = {
    // ans: ['A', 'B', 'C', 'D', 'E'],
    // titleId: '1772',
    // rights: 'A,B' // 正确答案，等效于数组 ['A', 'B', 'D']
// }

// 回答题目
// console.log(check(['A', 'B']))
