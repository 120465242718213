import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { isLogin } from '@/utils/isLogin'
import { setPageTitle } from '@/utils/set-title'
import { getUserToken } from '@/utils/token.config'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // progress bar style
import vuexState from '@/store';
import store from 'storejs'


const routes : Array<RouteRecordRaw> = []
const require_module = require.context('./modules', false, /.ts$/)
require_module.keys().forEach(file_name => {
    routes.push(require_module(file_name).default)
})

routes.push({
    path: '/:carchAll(.*)',
    component: () => import('../views/404.vue'),
    meta: {
        title: '页面不存在',
        isLogin: false
    }
})

// 页面保持最顶部
const scrollBehavior = () => {
    return { left: 0, top:0 };
}
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior
})

router.beforeEach((to: any, from , next) => {
    // if (window.navigator.mediaDevices)
    //判断设备是不是移动端
    if ( window.innerWidth > 800) {
        window.location.href = process.env.VUE_APP_APE_WEB
        return
    }
    NProgress.start()
    document.title = setPageTitle(to.meta.title)
    if (store.get('userInfo') && to.path === "/login") {
        next({name: 'home'})
    } else {
        if (store.get('token')) {
            vuexState.dispatch('login_token').then(res => res?  next() : next()).catch(err => next())
        } else {
            next()
        }
    }
})
router.afterEach(() => {
    NProgress.done()
})
export default router
