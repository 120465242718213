
/**
 * @description 统计正确答案的个数
 * @param {list} 用户当前试卷答完题的list
 * @returns length
 */
export const  statistics_num = <T extends object[]>(list: T | any) => {
    let rightLength = 0
    for(let i = 0; i < list.length; i++) {
        if(list[i]?.is_right) {
            rightLength++
        }
    }
    return rightLength
}


/**
 * @description 成绩计算 5题模式，10题模式与20题模式 
 *              答错不扣分，未达分别扣除 4、2、1分
 * @returns 需要扣除的分数
 * @param {list} 户当前试卷答完题的list
 * @param {total} 题的总数量
 */
const __mode_5: number = 4
const __mode_10: number = 2
const __mode_20: number = 1
export const unanswered = <T extends number>(list: T | any, total: T) => {
    let mode = null
    let null_Length = 0
    total === 5 ? mode = __mode_5 : (total === 10 ? mode = __mode_10 : mode = __mode_20)
    for(let i = 0; i < list.length; i++) {
        if(!list[i]?.user_answer) {
            null_Length++
        }
    }
    return null_Length * mode
}