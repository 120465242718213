interface count<T> {
    topic_answer: number | T
    choice_question_titlt: number | T
}
export interface tagInit {
    id: number
    tag: string
    img: string
    _count: count<string>
}

export class homeDataType {
    tags: tagInit[] = []
}

interface skillInit {
    desc: string
    id: number
    title: string
    type: number
}

export class skillList {
    list: skillInit[] = []
}
