import store from 'storejs'
import { Toast } from 'vant'
export class clearEacheHandal {
    clear() {
        store.remove('history')
        store.remove('answerId')
        store.remove('answer_all')
        store.remove('arrlist')
        store.remove('collect_tab')
        store.remove('tagsList')
        store.remove('answer_tab')
        try {
            for( let i=1; i <= 12; i++) {
                store.remove(`answerlist${i}`)
            }
        } catch (error) {
            console.log('🚀 ~ file: setup.vue ~ line 36 ~ error', error)
        }
        Toast.success('清除成功')
    }
}

export class exitClearHand{
    clear() {
        store.remove('history')
        store.remove('answerId')
        store.remove('answer_all')
        store.remove('arrlist')
        store.remove('collect_tab')
        store.remove('tagsList')
        store.remove('answer_tab')
        store.remove('token')
        store.remove('userInfo')
        try {
            for( let i=1; i <= 12; i++) {
                store.remove(`answerlist${i}`)
            }
        } catch (error) {
            console.log('🚀 ~ file: setup.vue ~ line 36 ~ error', error)
        }
    }
}

export class ClearAnswer{
    clear() {
        store.remove('answerId')
        store.remove('answer_all')
        store.remove('arrlist')
        store.remove('tagsList')
        try {
            for( let i=1; i <= 12; i++) {
                store.remove(`answerlist${i}`)
            }
        } catch (error) {
            console.log('🚀 ~ file: setup.vue ~ line 36 ~ error', error)
        }
    }
}