// @ts-ignore
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import 'animate.css'
import 'amfe-flexible'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import './global.scss'

import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css' //md 暗黑样式
import "github-markdown-css/github-markdown-light.css";
// 注入monaco 字体
import '../public/font/font_md.css'

const app = createApp(App)
// 代码高亮自定义指令
app.use(store).directive('highlight', function (el) {
    let blocks = el.querySelectorAll('pre code')
    blocks.forEach((block: any) => {
        hljs.highlightBlock(block)
    })
})
app.use(router)
app.mount('#app')
