import http from '../utils/request'

const serveUrl: any = {
    tags: '/answer/taglist',
    introduce: '/learn/file',
    skills: '/learn/skill'
}

// export interface tagInit{
//     id: number,
//     tag: string,
//     img: string
// }

export const tags = () => {
    return http.request({
        url:  serveUrl.tags,
        method: 'GET'
    })
}

export const introduce = () => {
    return http.request({
        url:  serveUrl.introduce,
        method: 'GET'
    })
}

export const skills = () => {
    return http.request({
        url:  serveUrl.skills,
        method: 'GET'
    })
}
