import http from '../utils/request'

const serveUrl: any = {
    choiceList: '/answer/choice/list',
    tagDetailChoice: '/answer/tagdetailchoice',
    getTrainLlist: '/answer/train/list',
    submitReoprt: '/answer/submit/reoprt',
    historyReoprt: '/answer/get/historyReoprt',
    getUserReoprt: '/answer/get/userReoprt',
    userErrorReport: '/answer/get/userErrorReoprt',
    answerDetail: 'answer/get/answer/detail'
}

// 随机5-10-20 题
export const choiceList = (num: number | string) => {
    return http.request({
        url: serveUrl.choiceList,
        method: 'get',
        params: {
            num
        }
    })
}

export const tagDetailChoice = (tagId: number | string) => {
    return http.request({
        url: serveUrl.tagDetailChoice,
        method: 'get',
        params: {
            tagId
        }
    })
}

// 专项随机训练
interface listDto {
    num: number
    list: object[]
}
export const getTrainLlist = (data: listDto) => {
    return http.request({
        url: serveUrl.getTrainLlist,
        method: 'POST',
        data
    })
}

export interface ReoprtDto {
    user_id?: number
    repport: string | number
    mode: string
    total: number
    grade: string
    reportList: object[]
}
export const submitReoprt = (data: ReoprtDto) => {
    return http.request({
        url: serveUrl.submitReoprt,
        method: 'POST',
        data
    })
}

export const historyReoprt = (cid: number) => {
    return http.request({
        url: serveUrl.historyReoprt,
        method: 'GET',
        params: {
            cid
        }
    })
}

export const getUserReoprt = (user_id: number) => {
    return http.request({
        url: serveUrl.getUserReoprt,
        method: 'GET',
        params: {
            user_id
        }
    })
}

export const userErrorReport = (user_id: number, pageNumber: number) => {
    return http.request({
        url: serveUrl.userErrorReport,
        method: 'GET',
        params: {
            user_id,
            pageNumber
        }
    })
}

export const answerDetail = (tid: number) => {
    return http.request({
        url: serveUrl.answerDetail,
        method: 'GET',
        params: {
            tid,
        }
    })
}