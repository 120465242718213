import CryptoJS from 'crypto-js'
/**
 *
 * @param str 答案解密
 * @returns
 */
export const fromCode = function (str: any) {
    //定义密钥，36个字母和数字
    var key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_hzzy'
    var len = key.length //获取密钥的长度
    var b,
        b1,
        b2,
        b3,
        d = 0,
        s //定义临时变量
    s = new Array(Math.floor(str.length / 3)) //计算加密字符串包含的字符数，并定义数组
    b = s.length //获取数组的长度
    for (var i = 0; i < b; i++) {
        //以数组的长度循环次数，遍历加密字符串
        b1 = key.indexOf(str.charAt(d)) //截取周期内第一个字符串，计算在密钥中的下标值
        d++
        b2 = key.indexOf(str.charAt(d)) //截取周期内第二个字符串，计算在密钥中的下标值
        d++
        b3 = key.indexOf(str.charAt(d)) //截取周期内第三个字符串，计算在密钥中的下标值
        d++
        s[i] = b1 * len * len + b2 * len + b3 //利用下标值，反推被加密字符的Unicode编码值
    }
    b = eval('String.fromCharCode(' + s.join(',') + ')') // 用fromCharCode()算出字符串
    return b //返回被解密的字符串
}

/**
 * 字符串加密解密 hex 版本
 * @function encrypt 解密
 * @function decrypt 加密
 */
const key = 'HZZYHI0123456789'
const iv = '0123456789abcdef'
export const encrypt = (encryptedVal: string) : string | null | undefined => {
    try {
        let encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedVal)
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
        const keyHex = CryptoJS.enc.Utf8.parse(key) // 秘钥
        const ivHex = CryptoJS.enc.Utf8.parse(iv) // 偏移量
        let decrypt = CryptoJS.AES.decrypt(srcs, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
        return decryptedStr.toString()
    } catch (error) {
        console.log('🚀 ~ file: encryption.ts:13 ~ error:', error)
    }
}

// 字符串加密算法
export const decrypt = (str: string | number): string | number | any => {
    try {
        const dataHex = CryptoJS.enc.Utf8.parse(str) // 需要加密的数据
        const keyHex = CryptoJS.enc.Utf8.parse(key) // 秘钥
        const ivHex = CryptoJS.enc.Utf8.parse(iv) // 偏移量
        const encrypted = CryptoJS.AES.encrypt(dataHex, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC, // 加密模式
            padding: CryptoJS.pad.Pkcs7
        })
        let encryptedVal = encrypted.ciphertext.toString()
        return encryptedVal
    } catch (error) {
        console.log('🚀 ~ file: encryption.ts:13 ~ error:', error)
    }
}
