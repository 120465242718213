import { reoprtRightListInit } from "@/store"

/**
 * @description 答题新增积分函数
 * @returns number-code
 * @param {code} 
 */
export const addAnswerInterral = (total: number, all_report: any, reoprtList: reoprtRightListInit[]) => {
    const __report: number = Number(all_report)
    console.log('🚀 ~ file: integralAnswer.ts:8 ~ total:', total, __report)
    const is_unanswer_length: number = unanswerCount<reoprtRightListInit[]>(reoprtList)
    return reps<number>(total, __report, is_unanswer_length)
}

const __total5: number = 5
const __total10: number = 10
const __total20: number = 20
const reps = <T extends number>(total: T, rep: T, is_unanswer_length: T) => {
    let mode = null
    if ( rep < 40) {
        total === __total5 ? mode = (5 - is_unanswer_length) : (total === __total10 ? mode = (10 - is_unanswer_length) : mode = (15 - is_unanswer_length))
    } else if (rep >= 40 && rep < 60) {
        total === __total5 ? mode = (10- is_unanswer_length) : (total === __total10 ? mode = (16- is_unanswer_length) : mode = (28- is_unanswer_length))
    } else if (rep >= 60 && rep < 80) {
        total === __total5 ? mode = (15 - is_unanswer_length) : (total === __total10 ? mode = (25 - is_unanswer_length) : mode = (38 - is_unanswer_length))
    } else if(rep >= 80 && rep < 100) {
        total === __total5 ? mode = (20 - is_unanswer_length) : (total === __total10 ? mode = (32 - is_unanswer_length) : mode = (65 - is_unanswer_length))
    } else {
        // 满分
        total === __total5 ? mode = 30 : (total === __total10 ? mode = 60 : mode = 100)
    }
    return mode <= 0 ? 0 : mode
}

/**
 * @description 未答题统计
 */
const unanswerCount = <T extends reoprtRightListInit[]>(list: T) => {
    let unLength = 0
    for(let i = 0; i < list.length; i++) {
        if(!list[i]?.user_answer) {
            unLength++
        }
    }
    return unLength
}

/**
 * @param num 当前得分下新增的积分
 * @param lenght 未答题的题数
 * @returns 未答一题 扣除一分
 */
const lastIntegraCode = <T extends number>(num: T, lenght: T) => num - lenght