import main from '@/layout/main.vue'
export default {
    path: '/mini-web/',
    redirect: '/mini-web/',
    component: main,
    children: [
        {
            path: '/about',
            name: 'about',
            component: () => import('../../views/centerMeun/about.vue'),
            meta: {
                title: '联系我们',
                isLogin: false
            }
        },
        {
            path: '/report',
            name: 'report',
            component: () => import('../../views/questionDetail/report.vue'),
            meta: {
                title: '成绩单',
                isLogin: false
            }
        },
        {
            path: '/feed',
            name: 'feed',
            component: () => import('../../views/centerMeun/feedback.vue'),
            meta: {
                title: '设置',
                isLogin: false
            }
        },
        {
            path: '/redeem_code',
            name: 'redeemCode',
            component: () => import('../../views/centerMeun/redeemCode.vue'),
            meta: {
                title: '兑换会员',
                isLogin: false
            }
        },
        {
            path: '/vip',
            name: 'vip_page',
            component: () => import('../../views/centerMeun/vip.vue'),
            meta: {
                title: '会员权益',
                isLogin: false
            }
        },
        {
            path: '/sponsor',
            name: 'sponsor',
            component: () => import('../../views/centerMeun/sponsor.vue'),
            meta: {
                title: '友情赞助',
                isLogin: false
            }
        },
        {
            path: '/tiger',
            name: 'tiger',
            component: () => import('../../views/draw/tiger.vue'),
            meta: {
                title: '扭蛋机',
                isLogin: true
            }
        },
        {
            path: '/turntable',
            name: 'turntable',
            component: () => import('../../views/draw/turntable.vue'),
            meta: {
                title: '幸运转盘',
                isLogin: true
            }
        },
        {
            path: '/member',
            name: 'member',
            component: () => import('../../views/member/member.vue'),
            meta: {
                title: '我的会员',
                isLogin: false
            }
        },
        {
            path: '/advanced',
            name: 'advanced',
            component: () => import('../../views/advanced/index.vue'),
            meta: {
                title: 'JS进阶',
                isLogin: false
            }
        },
        {
            path: '/advanced/detail',
            name: 'advDetail',
            component: () => import('../../views/advanced/detail/detail.vue'),
            meta: {
                title: '详情列表',
                isLogin: false
            }
        },
        {
            path: '/vip-conversion',
            name: 'conversion',
            component: () => import('../../views/member/conversion.vue'),
            meta: {
                title: '积分兑换',
                isLogin: false
            }
        },
        {
            path: '/vip-conversion/order-list',
            name: 'orderList',
            component: () => import('../../views/member/order-list/list.vue'),
            meta: {
                title: '订单列表',
                isLogin: false
            }
        },
        {
            path: '/vip-return-url',
            name: 'vipReturnUrl',
            component: () => import('../../views/member/payRetuenUrl.vue'),
            meta: {
                title: '支付成功',
                isLogin: true
            }
        }
    ]
}
