export interface TabbarType {
    name: string
    lable: string
    title: string
    checked: boolean
    select_icon: string
    icon: string
}
export class tabInit {
    tabList: TabbarType[] = [
        {
            name: 'home',
            lable: 'radio-1',
            title: '首页',
            checked: true,
            select_icon: 'tab_home_select',
            icon: 'tab_home'
        },
        {
            name: 'learn',
            lable: 'radio-2',
            title: '资源',
            checked: false,
            select_icon: 'tab_more_select',
            icon: 'tab_more'
        },
        {
            name: 'center',
            lable: 'radio-3',
            checked: false,
            title: '我的',
            select_icon: 'tab_my_select',
            icon: 'tab_my'
        }
    ]
}
