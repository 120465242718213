import Home from '../../views/Home.vue'
import defLayout from '@/layout/default.vue'
export default {
    path: '/mini-web/',
    redirect: '/mini-web/',
    component: defLayout,
    children: [
        {
            path: '/login',
            name: 'login',
            component: () => import('../../views/login.vue'),
            meta: {
                title: '登录'
            }
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: '猿前端',
                showTab: true,
                isLogin: false
            }
        },
        {
            path: '/home/skill',
            name: 'home-skill',
            component: () => import('../../views/homeview/skill.vue'),
            meta: {
                title: '面试技巧',
                isLogin: false
            }
        },
        {
            path: '/home/file',
            name: 'home-file',
            component: () => import('../../views/homeview/file.vue'),
            meta: {
                title: '介绍模板',
                isLogin: false
            }
        },
        {
            path: '/home/collection',
            name: 'home-collection',
            component: () => import('../../views/homeview/collection.vue'),
            meta: {
                title: '积分兑好礼',
                isLogin: false
            }
        },
        {
            path: '/question',
            name: 'question',
            component: () => import('../../views/question.vue'),
            meta: {
                title: '答题筛选',
                isLogin: false
            }
        },
        {
            path: '/answer',
            name: 'answer',
            component: () => import('../../views/questionDetail/answer.vue'),
            meta: {
                title: '答题详情',
                isLogin: false
            }
        },
        {
            path: '/interview',
            name: 'interview',
            component: () => import('../../views/interview/index.vue'),
            meta: {
                title: '问答题',
                isLogin: false,
                keepAlive: false
            }
        },
        {
            path: '/interview-detail',
            name: 'interviewDetail',
            component: () => import('../../views/interview/detail/detail.vue'),
            meta: {
                title: '问答题详情',
                isLogin: false
            }
        },
        {
            path: '/search',
            name: 'search',
            component: () => import('../../views/search.vue'),
            meta: {
                title: '搜索问答',
                showTab: false,
                isLogin: false,
                keepAlive: true
            }
        },
        {
            path: '/center',
            name: 'center',
            component: () => import('../../views/center.vue'),
            meta: {
                title: '个人中心',
                showTab: true,
                isLogin: false
            }
        },
        {
            path: '/learn',
            name: 'learn',
            redirect: '/learn/website',
            component: () => import('../../views/learn.vue'),
            meta: {
                title: '学习更多',
                showTab: true,
                isLogin: false
            },
            children: [
                {
                    path: '/learn/website',
                    name: 'website',
                    component: () => import('../../views/learnChild/website.vue')
                },
                {
                    path: '/learn/tooldow',
                    name: 'tooldow',
                    component: () => import('../../views/learnChild/tooldow.vue')
                },
                {
                    path: '/learn/handbook',
                    name: 'handbook',
                    component: () => import('../../views/learnChild/handbook.vue')
                },
                {
                    path: '/learn/resume',
                    name: 'resume',
                    component: () => import('../../views/learnChild/resume.vue')
                }
            ]
        },
        {
            path: '/collect',
            name: 'collect',
            component: () => import('../../views/centerMeun/collect.vue'),
            meta: {
                title: '我的收藏',
                isLogin: true
            }
        },
        {
            path: '/myReport',
            name: 'historyReport',
            component: () => import('../../views/homeview/historyReport.vue'),
            meta: {
                title: '历史成绩',
                isLogin: true
            }
        },
        {
            path: '/wrong',
            name: 'wrong',
            component: () => import('../../views/centerMeun/wrong.vue'),
            meta: {
                title: '我的错题',
                isLogin: true
            }
        },
        {
            path: '/choose-detail',
            name: 'chooseDetail',
            component: () => import('../../views/centerMeun/tempAnswer/chooiseDetail.vue'),
            meta: {
                title: '答题详情',
                isLogin: true
            }
        },
        {
            path: '/feedback',
            name: 'feedback',
            component: () => import('../../views/centerMeun/feedback.vue'),
            meta: {
                title: '问题反馈',
                isLogin: true
            }
        },
        {
            path: '/AuthorizedLogin',
            name: 'AuthorizedLogin',
            component: () => import('../../views/AuthorizedLogin.vue'),
            meta: {
                title: '授权',
                isLogin: false
            }
        },
        {
            path: '/agreeLogin',
            name: 'agreeLogin',
            component: () => import('../../components/agree_login.vue'),
            meta: {
                title: '用户协议',
                isLogin: false
            }
        },
        {
            path: '/setup',
            name: 'setup',
            component: () => import('../../views/centerMeun/setup.vue'),
            meta: {
                title: '设置',
                isLogin: false
            }
        },
        {
            path: '/vip-conversion/address',
            name: 'address',
            component: () => import('../../views/member/address.vue'),
            meta: {
                title: '地址管理',
                isLogin: false
            }
        },
        {
            path: '/integral-detail',
            name: 'integralDetail',
            component: () => import('../../views/centerMeun/integralDetails.vue'),
            meta: {
                title: '积分详情',
                isLogin: true
            }
        }
    ]
}