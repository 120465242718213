import { createStore } from 'vuex'
import { check } from '../utils/verifyValue'
import { MyisArray } from '@/utils/isArray'
import { ReoprtDto, submitReoprt } from '@/api/answer'
import { statistics_num, unanswered } from '@/utils/answer/statisticsPublic'
import router from '@/router'
import { addCodeRedis, isToken } from "@/api/auth";
import { Toast } from 'vant'
import store from 'storejs'
import { addAnswerInterral } from '@/utils/answer/integralAnswer'
import { decrypt } from '@/utils/myhash'
import vuex from '@/store'
interface listInit {
    user_id?: number
    repport: string | number
    mode: string
    total: number
    grade: string
}
export interface reoprtRightListInit {
    is_right?: boolean
    user_answer?: string
    title_id: number
    user_id?: number
}

export interface UserInfoInit {
    avatar?: string | number
    avatar_url?: string | any
    code_integral?: number
    createAt?:string
    email?:string
    flag?: number
    id?: number | string |undefined | any
    is_vip?: boolean
    password?: string
    signin_time?: string
    updateAt?: string
    username?: string
    uuid?: string | number | any
}
interface State {
    submitAnswerList: object[]
    reoprtRightList: any
    answerreoprt: listInit
    userInfo: UserInfoInit
    title_id: number
}
interface Answer {
    aid?: number
    answer?: string[] | any
    answer_title?: string
    titleId?: number
    isright?: boolean
}
export default createStore<State>({
    state: {
        submitAnswerList: [],
        reoprtRightList: [],
        answerreoprt: {
            user_id: -1,
            grade: '',
            mode: '',
            total: 5,
            repport: ''
        },
        userInfo: {},
        title_id: 0
    },
    mutations: {
        __addAnswer(state, obj) {
            console.log('🚀 ~ file: index.ts:69 ~ obj', obj)
            let index = state.submitAnswerList.findIndex((x: Answer) => x.titleId == obj.titleId)
            if (state.submitAnswerList.findIndex((x: Answer) => x.titleId == obj.titleId) != -1) {
                state.submitAnswerList.splice(index, 1, obj)
            } else {
                state.submitAnswerList.push(obj)
            }
        },
        __clearList(state) {
            state.submitAnswerList = []
            state.reoprtRightList = []
            state.answerreoprt = {
                user_id: state.userInfo.id ? state.userInfo.id : false,
                grade: '',
                mode: '',
                total: 5,
                repport: ''
            }
        },
        __isRight(state, data) {
            /**
             * @answer_isright 验证当前选择题是否正确 -- type:boolean
             * @isright 新增答案属性记录是否正确
             */
            const answer_isright: boolean = check(data.question, data.answer)
            state.submitAnswerList.length &&
                state.submitAnswerList.forEach((item: Answer) => {
                    if (item.titleId === data.question.titleId) {
                        item.isright = answer_isright
                        
                        // 往提交的数据中添加用户的选择
                        state.reoprtRightList.length && state.reoprtRightList.forEach((items: any) => {
                                if (item.titleId == items.title_id) {
                                    items.is_right = answer_isright
                                    items.user_answer = MyisArray(item.answer)
                                    items.user_id = state.userInfo.id ? state.userInfo.id : false
                                }
                            })
                    }
                })
            // 顺序模式对比完答案将答题列表存储到本地缓存处理
            if (data.obj.type === 'ab5-b2b0-484d-b788') {
                store.set(`order-${data.obj.tagId}`, state.submitAnswerList)
            }
        },
        __userReport(state, data) {
            data && data.forEach((item: { tid: number }) => {
                state.reoprtRightList.push({
                    title_id: item.tid
                })
            })
        },
        __submitAnswer(state, obj) {
            // 提交前还原答题模式参数（防止url手动篡改）
            if (obj.mode === 'a3c-2153-4b38-b80a') {
                state.answerreoprt.mode = 's'
            } else if(obj.mode === '033-01bf-4b2a-928c'){
                state.answerreoprt.mode = 'z'
            } else {
                state.answerreoprt.mode = 'x'
            }
            state.answerreoprt.total = obj.total
            state.answerreoprt.user_id = state.userInfo.id ? state.userInfo.id : false
            // all_report: 未扣除《未答题的题应该扣除的分数》之前的总分
            let all_report = ((100 / obj.total) * statistics_num(state.reoprtRightList)).toFixed(0)
            // 计算出最终成绩 _report
            let _report = ((100 / obj.total) * statistics_num(state.reoprtRightList) - unanswered(state.reoprtRightList, obj.total)).toFixed(0)
            state.answerreoprt.repport = Number(_report) <= 0 ? 0 : Number(_report)
            const rep = state.answerreoprt.repport
            if ( rep < 40) {
                state.answerreoprt.grade = 'D'
            } else if (rep <= 40 && rep < 60) {
                state.answerreoprt.grade = 'C'
            } else if (rep <= 60 && rep < 80) {
                state.answerreoprt.grade = 'B'
            } else if(rep <= 80 && rep < 100) {
                state.answerreoprt.grade = 'A'
            } else {
                state.answerreoprt.grade = 'S'
            }
            // Number(state.answerreoprt.repport) > 80 ? (state.answerreoprt.grade = '钻石') : (Number(state.answerreoprt.repport) > 60 ? (state.answerreoprt.grade = '黄金'): state.answerreoprt.grade = '废铁')
            // 提交到数据库
            const data: ReoprtDto = {
                ...state.answerreoprt,
                reportList: state.reoprtRightList
            }
            console.log('report', data);
            
            submitReoprt(data).then(res => {
                if(res.code === 200) {
                    const integrCode = addAnswerInterral(obj.total, all_report, state.reoprtRightList)
                    console.log('🚀 ~ file: index.ts:159 ~ integrCode:', integrCode)

                    // 写入redis 缓存记录积分
                    if (state.userInfo?.id) {
                        let data: {user_id: number, score: number} = {
                            user_id: state.userInfo.id,
                            score: decrypt(integrCode)
                        } 
                        addCodeRedis(data).then(response => {
                            if (response.code === 200) {
                                router.replace({name: 'report', query: {cid: res.data.cid}})
                                // 清空记录
                                vuex.dispatch('clearList')
                            } else {
                                return Toast('网络错误请稍后重试！')
                            }
                        })
                    } else {
                        router.replace({name: 'report', query: {cid: res.data.cid}})
                        // 清空记录
                        vuex.dispatch('clearList')
                    }
                } else {
                    Toast('提交失败， 请重新尝试')
                }
            })
        },
        // 是否从缓存注入
        __isAddAnswerQuestion(state, tag_id) {
            const list: any[] = store.get(`order-${tag_id}`) || []
            state.submitAnswerList = list
            state.title_id = list[list.length - 1]?.titleId || 0
        },
        // 验证token是否过期
        __login_token(state, res) {
            if (res.code === 200) {
                state.userInfo = res.data
                store.set('userInfo', res.data)
                if (res.data.flag === 1) {
                    // 账号被封禁用
                    store.remove('token')
                    store.remove('userInfo')
                    state.userInfo = {}
                    
                }
            } else {
                // token 已过期 移除token
                store.remove('token')
                store.remove('userInfo')
                state.userInfo = {}
            }
        }
    },
    actions: {
        addAnswer({ commit }, obj) {
            commit('__addAnswer', obj)
        },
        clearList({ commit }) {
            commit('__clearList')
        },
        // 计算答案是否正确
        isRight({ commit }, data) {
            commit('__isRight', data)
        },
        // 存贮最后答题提交的数据
        userReport({ commit }, data) {
            commit('__userReport', data)
        },
        // 提交答案接口
        submitAnswer({ commit }, data) {
            commit('__submitAnswer', data)
        },
        // 是否从缓存注入
        isAddAnswerQuestion({ commit }, tag_id) {
            commit('__isAddAnswerQuestion', tag_id)
        },
        // token 验证
        login_token({ commit }) {
            return new Promise((resolve, reject) => {
                isToken().then(res => {
                    if (res.code === 200) {
                        commit('__login_token', res)
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }).catch(err => {
                    reject(false)
                })
            })
        }
    },
    modules: {}
})
